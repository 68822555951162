import type { DirectoryItemAdmin } from '~/modules/directory/type';
import type { AdminStatusInfo } from '@rocco/ui/tile-shared/view-models/tile';
import dayjs from 'dayjs';

export const mapDirectoryItemAdminToTile = (
    item: DirectoryItemAdmin
): AdminStatusInfo => {
    const {
        createdBy,
        createdDate,
        approvedBy,
        approvedDate,
        publishedBy,
        publishedDate,
        homePinned,
    } = item;

    const created = {
        createdBy: createdBy
            ? formatName(createdBy.firstName, createdBy.lastName)
            : fallbackName,
        createdAt: createdDate
            ? dayjs(createdDate).format('DD/MM/YYYY, hh:mm A')
            : '',
    };

    const approved =
        approvedDate && approvedBy
            ? {
                  approvedBy: formatName(
                      approvedBy.firstName,
                      approvedBy.lastName
                  ),
                  approvedAt: approvedDate
                      ? dayjs(approvedDate).format('DD/MM/YYYY, hh:mm A')
                      : '',
              }
            : undefined;

    const published =
        publishedDate && publishedBy
            ? {
                  publishedBy: formatName(
                      publishedBy.firstName,
                      publishedBy.lastName
                  ),
                  publishedAt: publishedDate
                      ? dayjs(publishedDate).format('DD/MM/YYYY, hh:mm A')
                      : '',
              }
            : undefined;

    return {
        created,
        approved,
        published,
        pinned: !!homePinned,
    };
};

const fallbackName = 'Anonymous User';

const formatName = (firstName: string, lastName: string) => {
    if (!firstName && !lastName) {
        return fallbackName;
    }
    return `${firstName} ${lastName}`;
};
